@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Assistant", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.alice-carousel__next-btn-item {
  display: none !important;
}
.alice-carousel__prev-btn-item {
  display: none !important;
}

.tech-slideshow {
  min-height: 300px;
  /* max-width: 800px;
  margin: 0 auto; */
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.tech-slideshow > div {
  min-height: 300px;
  width: 2000px;

  position: relative;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
}
.tech-slideshow .mover-1 {
  animation: moveSlideshow 20s linear infinite;
}
.tech-slideshow .mover-2 {
  opacity: 0;
  transition: opacity 0.2s ease-out;
  background-position: 0 -200px;
  animation: moveSlideshow 30s linear infinite;
}
.tech-slideshow:hover .mover-2 {
  opacity: 1;
}

@keyframes moveSlideshow {
  /* 100% {
    transform: translateX(-66.6666%);
  } */
  100% {
    transform: translateX(-66.6666%);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
/* @mixin white-gradient {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  
  overflow: hidden;
  position: relative;
  width: 960px;
  margin: 0 auto;
}

.slider::before,
::after {
  content: "";
  position: relative;
  width: 200px;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::after {
  left: 0;
  top: 0;
}
.slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.slide {
  height: 100px;
  width: 250px;
} */
